import makeReducerFor from "./_genericDbReducer";
import { createSelector } from "reselect";
import { getFlowItemsForSelectedFlow, getFlowItemsArray } from "./flowItems";
import tryParseJSON from "../helpers/tryParseJSON";
import type {
    FlowItem,
    FlowCase,
    FlowEmpty,
    FlowCaseAndEmpty,
    FlowErrorsByItemId,
    FlowFilter,
} from "../types/flowTypes";
import { DestinationFieldRestriction } from "../types/stores/vars";
const myGenericReducer = makeReducerFor("FLOW_CASE", "FlowCaseId");
import subItemReducer from "./_genericFlowSubItemReducer";
import { getFlowEmptiesArray } from "./flowEmpties";

const myReducer = (state = {}, action) => subItemReducer(myGenericReducer(state, action), action);
export default myReducer;

///////// SELECTORS /////////////

export type FlowCasesByItemId = {
    [number]: Array<FlowCase>,
};

export type FlowCasesAndFlowEmpties = {
    [number]: Array<FlowCaseAndEmpty>,
};

export const getFlowCasesArray = createSelector(
    state => state.flowCases.byId,
    (flowCasesById: {| [number]: FlowCase |}): Array<FlowCase> => {
        const r: Array<FlowCase> = Object.values(flowCasesById);
        return r;
    }
);

export const getFlowCasesAndEmptyArray = createSelector(
    state => state.flowCases.byId,
    (flowCasesById: {| [number]: FlowCaseAndEmpty |}): Array<FlowCaseAndEmpty> => {
        const r: Array<FlowCaseAndEmpty> = Object.values(flowCasesById);
        return r;
    }
);

export const getFlowCasesAndEmptiesByFlowItemId = createSelector(
    state => getFlowCasesAndEmptyArray(state),
    state => getFlowEmptiesArray(state),
    (flowCases: Array<FlowCaseAndEmpty>, flowEmpties: Array<FlowEmpty>): FlowCasesAndFlowEmpties =>
        flowCases
            .sort((a, b) => a.FlowCasePriority - b.FlowCasePriority)
            .reduce((acc, row) => {
                if (acc[row.FlowItemId] == null) {
                    acc[row.FlowItemId] = [];
                }
                const EmptyItem = flowEmpties.filter(x => x.FlowItemId == row.ChildFlowItemId)[0];

                row.FlowEmpty = EmptyItem;
                acc[row.FlowItemId].push(row);

                return acc;
            }, {})
);

export const getFlowCasesByFlowItemId = createSelector(
    state => getFlowCasesArray(state),
    (flowCases: Array<FlowCase>): FlowCasesByItemId =>
        flowCases.reduce((acc, row) => {
            if (acc[row.FlowItemId] == null) {
                acc[row.FlowItemId] = [];
            }
            acc[row.FlowItemId].push(row);
            return acc;
        }, {})
);

export const getFlowCasesForSelectedFlow = createSelector(
    state => state.selected.flow,
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowCasesByFlowItemId(state),
    (selectedFlow: number, flowItems: Array<FlowItem>, flowCasesByItemId: FlowCasesByItemId): Array<FlowCase> => {
        let result = [];
        const itemIds = flowItems.map(fi => fi.FlowItemId);
        for (const itemId of itemIds) {
            const cases = flowCasesByItemId[itemId];
            if (cases != null) {
                result = result.concat(cases);
            }
        }
        return result;
    }
);

const flowCasesAndEmpties = (
    flowItems: Array<FlowItem>,
    flowCaseEmptiesByItemId: FlowCasesAndFlowEmpties
): Array<FlowCaseAndEmpty> => {
    let result = [];
    const itemIds = flowItems.map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const cases = flowCaseEmptiesByItemId[itemId];
        if (cases != null) {
            result = result.concat(cases);
        }
    }
    return result;
};

export const getFlowCasesAndEmptiesForSelectedFlow = createSelector(
    state => getFlowItemsForSelectedFlow(state),
    state => getFlowCasesAndEmptiesByFlowItemId(state),
    flowCasesAndEmpties
);

export const getFlowCasesAndEmptiesForAllFlows = createSelector(
    state => getFlowItemsArray(state),
    state => getFlowCasesAndEmptiesByFlowItemId(state),
    flowCasesAndEmpties
);

const casesToErrorsById = (
    flowCaseEmpties: Array<FlowCaseAndEmpty>,
    flowItems: Array<FlowItem>,
    fieldsById: Array<Object>,
    fieldRestrictions: Array<DestinationFieldRestriction>,
    flowFilters: Array<FlowCase>
): FlowErrorsByItemId => {
    const errors = {};
    const itemIds = flowItems.filter(fi => fi.FlowItemType.toLowerCase() == "case").map(fi => fi.FlowItemId);
    for (const itemId of itemIds) {
        const theseCases = flowCaseEmpties.filter(x => x.FlowItemId == itemId);
        errors[itemId] = validateFlowCases(theseCases, fieldsById, fieldRestrictions, flowFilters, flowItems);
    }
    return errors;
};

import { getFlowFiltersForSelectedFlow, getFlowFiltersArray } from "./flowFilters";
export const getCaseErrorsForSelectedFlow = createSelector(
    state => getFlowCasesAndEmptiesForSelectedFlow(state),
    state => getFlowItemsForSelectedFlow(state),
    state => state.fields.byId,
    state => state.vars.destinationFieldRestrictions,
    state => getFlowFiltersForSelectedFlow(state),
    casesToErrorsById
);

export const getCaseErrorsForAllFlows = createSelector(
    state => getFlowCasesAndEmptiesForAllFlows(state),
    state => getFlowItemsArray(state),
    state => state.fields.byId,
    state => state.vars.destinationFieldRestrictions,
    state => getFlowFiltersArray(state),
    casesToErrorsById
);

//////////////////// HELPERS /////////////////////////////

// ***** Must pass an array of all FlowCase rows belonging to a particular itemId. *****
// This way we can tell if percentages don't add up to 100%, etc.
import getFieldIdsFromRules from "../helpers/getFieldIdsFromRules";
import { restrictedFieldUsed } from "./flowFilters";
const validateFlowCases = (
    flowCases: Array<FlowCaseAndEmpty>,
    fieldsById: Array<Object>,
    fieldRestrictions: Array<DestinationFieldRestriction>,
    flowFilters: Array<FlowFilter>,
    flowItems: Array<FlowItem>
): Array<string> => {
    const errors = [];
    const flowCasesNoBalance = flowCases.filter(x => !x.IsBalance);
    if (flowCasesNoBalance.length == 0) {
        errors.push("Must have at least one case assigned.");
    }

    flowCases.forEach(x => {
        if (x.FlowCaseCriteria == null && x.FlowCaseLabel == null) {
            errors.push("Criteria cannot be blank.");
        } else if (x.FlowCaseCriteria != null && !x.IsBalance) {
            const includeObject = tryParseJSON(x.FlowCaseCriteria) || {};
            const rules = includeObject.rules || [];
            const caseFieldIds = getFieldIdsFromRules(rules);
            if (rules.length == 0) {
                errors.push("'" + x.FlowCaseLabel + "' case must have at least one rule.");
            } else if (!includeObject.valid) {
                errors.push("'" + x.FlowCaseLabel + "' case rules are not completely filled out.");
            } else if (!fieldsById || caseFieldIds.filter(x => !fieldsById[parseInt(x)]).length > 0) {
                errors.push("'" + x.FlowCaseLabel + "' case has fields no longer available.");
            } else if (fieldsById && caseFieldIds.filter(x => fieldsById[parseInt(x)].TableActive === "N").length > 0) {
                errors.push("'" + x.FlowCaseLabel + "' case has fields no longer available at this time.");
            }

            if (fieldRestrictions != null && fieldRestrictions.length > 0) {
                const restrictedFields = fieldRestrictions.filter(
                    fr => fr.CompanyIsDataProvider && caseFieldIds.includes(fr.FieldKey.toString())
                );
                if (restrictedFields.length > 0) {
                    const caseItem = flowItems.find(fi => fi.FlowItemId == x.FlowItemId);
                    if (
                        restrictedFieldUsed(
                            caseItem.FlowId,
                            flowItems,
                            flowFilters,
                            flowCases,
                            restrictedFields,
                            fieldsById
                        )
                    ) {
                        errors.push("'" + x.FlowCaseLabel + "' case uses restricted fields.");
                    }
                }
            }
        }
    });

    const flowEmpties: Array<FlowEmpty> = flowCases.map(x => x.FlowEmpty).filter(x => x != null);
    if (flowEmpties.length != flowCases.length) {
        errors.push("Case Error 928"); // Empties are missing
    }

    const uniqueCriteria = [...new Set(flowEmpties.map(x => x.FlowEmptyCriteria))];

    if (flowEmpties.filter(x => x.FlowEmptyCriteria == "" || x.FlowEmptyCriteria == null).length > 0) {
        errors.push("Some cases are missing labels.");
    } else if (uniqueCriteria.length != flowEmpties.length) {
        errors.push("Some cases have duplicate labels.");
    }
    return errors;
};
